<template>
  <div class="app">
    <el-card class="box-card">
      <!--列表-->
      <div v-if="!addStatus">
        <div class="listsHeader">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-popover
                placement="top"
                width="160"
                v-model="delAllStatus"
                style="margin-right: 8px;">
                <p>您確認要删除嗎？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="delAllStatus = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="doDeleteAll">确定</el-button>
                </div>
                <el-button size="small" type="warning" slot="reference">
                  <i class="fa fa-trash"></i> 批量删除
                </el-button>
              </el-popover>

              <el-button
                size="small"
                type="info"
                @click="$refs.RoomTable.clearSelection()"
                style="margin-right: 8px;">
                取消選擇</el-button>

              <el-button
                size="small"
                type="success"
                @click="addToEdit()"
                style="margin-right: 8px;">
                <i class="el-icon-plus" style="font-weight: 900;"></i>  添加商品</el-button>
            </el-col>

            <el-col :span="12">
              <el-input placeholder="填寫查詢內容" size="small" v-model="query" class="employees-input">
                <el-button slot="append" @click="queryBin">
                  <i class="fa fa-search"></i>
                </el-button>
              </el-input>
            </el-col>
          </el-row>
        </div>
        <el-table
          class="lists"
          ref="RoomTable"
          :data="lists.list"
          v-loading="loading"
          @selection-change="handleSelectionChange"
          border
          style="width: 100%;">
          <el-table-column fixed type="selection" width="40"></el-table-column>
          <el-table-column fixed prop="id" width="80" label="編號"></el-table-column>
          <el-table-column fixed prop="title" label="商品名稱"></el-table-column>
          <el-table-column label="上架狀態" width="80">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-switch
                  v-model="scope.row.isShelves"
                  active-color="#67C23A"
                  inactive-color="#eee"
                  @change="doLocked(scope.row.id, scope.row.isShelves)"
                ></el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="creationTime" label="添加時間" width="180"></el-table-column>
          <el-table-column fixed="right" label="操作" width="130">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-dropdown
                  split-button
                  type="primary"
                  size="small"
                  @command="actionBtn">
                  <span @click="addToEdit(scope.row.id)">
                    <i class="el-icon-edit-outline" style="font-weight: 900;"></i> 編輯
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      style="color: #F56C6C;"
                      :command="{id:scope.row.id,name:'recycle'}">
                      <i class="fa fa-trash-alt"></i>刪除
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="pager">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="lists.pageSize"
            :total="lists.total"
          ></el-pagination>
        </div>
      </div>

      <!--添加/编辑-->
      <div v-else>
        <div class="listsHeader">
          <el-row :gutter="24">
            <el-col :span="11">
              <el-button size="mini" type="warning" @click="addToEdit">
                <i class="fa fa-reply"></i>返回
              </el-button>
            </el-col>
            <el-col :span="13">{{editStatus?'編輯用戶':'添加用戶'}}</el-col>
          </el-row>
        </div>

        <el-row :gutter="24">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="member-ruleForm">

            <el-col :span="24">
              <el-form-item label="商品名称" prop="title">
                <el-input
                  maxlength="20"
                  show-word-limit
                  placeholder="請填寫商品名称"
                  v-model="ruleForm.title"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  type="textarea"
                  maxlength="100"
                  show-word-limit
                  placeholder="商品备注..."
                  :rows="5"
                  v-model="ruleForm.remark"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <div
                style="color: #C03639;font-size: 13px;text-align:left;float: left;"
                v-if="editStatus"
              ></div>
              <div style="text-align: right;">
                <el-button @click="resetForm('ruleForm')">重置</el-button>
                <el-button
                  type="primary"
                  @click="submitForm('ruleForm')"
                >提交</el-button>
              </div>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
  import defaultUserHead from '../../../assets/img/admin/user/1.jpg'
  export default {
    data() {
      return {
        userHeadImg:defaultUserHead,
        type: "0",
        query: "",
        sexType: ["保密", "男", "女"],
        sexColor: ["info", "", "danger"],
        lists: {
          total: 0,
          pageSize: 0,
          list: [],
        },
        multipleSelection: [],
        page: 1,
        delAllStatus: false,
        delAllStatus2: false,
        loading: false,

        // 添加/编辑
        addStatus: false,
        editStatus: false,
        rules: {
          title: [
            { required: true, message: "请填写商品名称", trigger: "blur" },
            {
              min: 3,
              max: 20,
              message: "长度在 3 到 20 个字符",
              trigger: "blur",
            },
          ],
        },
        ruleForm: {
          id: 0,
          title: "",
          remark: "",
          isShelves: "",
        },
      };
    },
    mounted() {
      let that = this;
      that.getList();
    },
    methods: {
      // 添加/编辑用户
      addToEdit(id) {
        let that = this;
        if (id >= 1) {
          that.getAdminInfo(id);
          that.addStatus = !that.addStatus;
          that.editStatus = true;
        } else {
          that.ruleForm = {
            id: 0,
            title: "",
            remark: "",
            isShelves: "",
          };
          that.addStatus = !that.addStatus;
          that.editStatus = false;
        }
      },
      // 获取用户信息
      getAdminInfo(id) {
        let that = this;
        let param = that.$atk.json_to_url({
          id: id,
        });
        this.$http.GET(
          "/v1/booking/getGoodInfo?" + param,
          function (ret) {
            if (ret.errcode == 0) {
              that.ruleForm = {
                ...ret.data,
                isShelves: ret.data.isShelves.toString()
              };
            } else if (ret.errcode == 1) {
              that.$message({
                message: ret.errmsg,
                type: "warning",
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      // 提交添加/编辑
      doAddToEdit() {
        let that = this;
        let pDate = {
          ...that.ruleForm,
          isShelves: parseInt(that.ruleForm.isShelves),
        }
        that.$http.POST("/v1/booking/addOrEditGood", pDate, function (ret) {
            if (ret.errcode == 0) {
              that.$notify({
                title: "成功",
                message: ret.errmsg,
                type: "success",
              });
              that.getList();
              if(pDate.id <= 0) {
                that.addStatus = !that.addStatus;
                that.editStatus = !that.editStatus;
              }
            } else if (ret.errcode >= 1) {
              that.$notify.error({
                title: "错误",
                message: ret.errmsg,
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      // 提交
      submitForm(formName) {
        let that = this;
        that.$refs[formName].validate((valid) => {
          if (valid) {
            that.doAddToEdit();
          } else {
            return false;
          }
        });
      },
      // 重置
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // 操作按钮
      actionBtn(command) {
        let that = this;
        if (command.name == "recycle") {
          that.$confirm("您确认要刪除吗?", "刪除提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              that.doDelete([command.id]);
            })
            .catch(() => {
              that.$notify({
                title: "刪除提示",
                message: "已取消刪除",
                type: "warning",
              });
            });
        }
      },
      // 获取账户列表
      getList(page = 0) {
        let that = this;
        let param = that.$atk.json_to_url({
          page: page || parseInt(that.page),
          query: that.query,
        });
        that.loading = true;
        this.$http.GET(
          "/v1/booking/getGoodsList?" + param, (ret)=> {
            that.loading = false;
            if (ret.errcode == 0) {
              let data = ret.data || [];
              for (let i in data.list) {
                data.list[i].isShelves = data.list[i].isShelves?true:false;
              }
              that.lists = data;
            } else if (ret.errcode >= 1) {
              that.$message({
                message: ret.errmsg,
                type: "warning",
              });
            }
          }, (err)=> {
            that.loading = false;
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      // 分页
      handleCurrentChange(val) {
        this.page = val;
        this.getList(val);
      },
      // 设置商品上架状态
      doLocked (id, isShelves) {
        let that = this;
        let s = isShelves =! isShelves

        let param = {
          id: id,
          isShelves: s?0:1
        };
        that.$http.PUT(
          "/v1/booking/setGoodShelves", param,function (ret) {
            if (ret.errcode == 0) {
              that.$notify({
                title: "成功",
                message: ret.errmsg,
                type: "success",
              });
            } else if (ret.errcode == 1) {
              that.$notify.error({
                title: "错误",
                message: ret.errmsg,
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      // 刪除商品
      doDelete(arrayId) {
        let that = this;
        let param = that.$atk.json_to_url({
          ids: arrayId,
        });
        that.$http.DELETE(
          "/v1/booking/delGood?" + param,
          function (ret) {
            if (ret.errcode == 0) {
              that.$notify({
                title: "成功",
                message: ret.errmsg,
                type: "success",
              });
              for (let key in arrayId) {
                that.deleteItem(arrayId[key], that.lists.list);
              }
            } else if (ret.errcode == 1) {
              that.$notify.error({
                title: "错误",
                message: ret.errmsg,
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      // 删除列表用户
      deleteItem(item, list) {
        for (let key in this.lists.list) {
          if (list[key].id === item) {
            list.splice(key, 1);
          }
        }
      },
      // 选择商品
      handleSelectionChange(val) {
        this.multipleSelection = val;
        //let row = val.filter((val) => val.id == 1);
        //this.$refs.RoomTable.toggleRowSelection(row[0], false);
      },
      // 刪除已选择商品
      doDeleteAll() {
        let that = this;
        let length = that.multipleSelection.length;
        if (length <= 0) {
          that.$notify({
            title: "刪除提示",
            message: "请勾选刪除商品",
            type: "warning",
          });
          that.delAllStatus = false;
          return;
        }
        let idList = [];
        for (let i in that.multipleSelection) {
          idList[i] = that.multipleSelection[i].id;
        }
        that.doDelete(idList);
        that.delAllStatus = false;
      },
      // 查询
      queryBin() {
        let that = this;
        if (!that.query) {
          that.$message({
            message: "请输入查询内容",
            type: "warning",
          });
          return;
        }
        this.getList();
      },
    },
  };
</script>

<style scoped lang="scss">
  .upload-block {
    text-align: center;
    line-height: 80px;
    height: 80px;
    .el-upload {
      width: 100%;
      height: 100%;
      .el-upload-dragger {
        width: 100%;
        height: 100%;
        .el-upload__text {
          position: relative;
          font-size: 1.2em;
          color: #409eff;
          i {
            font-size: 1.5em;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .box-card {
    min-height: 10px;
    .listsHeader {
      min-height: 50px;
    }
    .pager {
      padding-top: 8px;
    }
  }
  .employees-input {
    button {
      height: 100%;
      padding: 9px 20px;
      border-radius: 0px 3px 3px 0px;
      color: #fff;
      background-color: #25334e;
    }
    button:hover {
      background-color: #182132;
    }
  }
  .table-user-head {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  /* 列表 */
  .listsHeader{
    .el-input-group__prepend .el-select {
      width: 90px;
    }
  }
  /* 编辑或者添加 */
  .member-ruleForm{
    .avatar-uploader {
      .el-upload {
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar {
        width: 178px;
        height: 178px;
        display: block;
        border-radius: 6px;
      }
    }
  }
</style>
